import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, ReplaySubject, take, tap } from 'rxjs';
import { ActivePlan, Profile, ProfileResponse } from './user.types';
import { environment } from '@env/environment';
import { HttpNormalResponseData } from '@shared/interfaces/http-response-interface';

@Injectable({ providedIn: 'root' })
export class UserService {
	private _serverUrl: string = environment.serverUrl;
	private _activePlan: ReplaySubject<ActivePlan> = new ReplaySubject<ActivePlan>(1);
	private _user: ReplaySubject<Profile> = new ReplaySubject<Profile>(1);

	/**
	 * Constructor
	 */
	constructor(private _httpClient: HttpClient) {}

	// -----------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------

	/**
	 * Setter & getter for user
	 *
	 * @param value
	 */
	set user(value: Profile) {
		// Store the value
		this._user.next(value);
	}

	get user$(): Observable<Profile> {
		return this._user.asObservable();
	}

	/**
	 * Setter & getter for active plan
	 *
	 * @param value
	 */
	set active_plan(value: ActivePlan) {
		// Store the value
		this._activePlan.next(value);
	}

	get active_plan$(): Observable<ActivePlan> {
		return this._activePlan.asObservable();
	}

	// -----------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------

	/**
	 * Get the current logged in user data
	 */
	get(): Observable<HttpNormalResponseData<ProfileResponse>> {
		return this._httpClient
			.get<HttpNormalResponseData<ProfileResponse>>(`${this._serverUrl}/profile`)
			.pipe(
				tap((user) => {
					this._user.next(user.data.user);
					this._activePlan.next(user.data.active_plan);
				})
			);
	}

	updateProfileImage(imageUrl: string): void {
		this.user$.pipe(take(1)).subscribe((user) => {
			const updatedUser = { ...user, image: imageUrl };
			this._user.next(updatedUser);
		});
	}

	/**
	 * Update the user
	 *
	 * @param user
	 */
	update(user: Profile): Observable<any> {
		return this._httpClient.patch<Profile>('api/common/user', { user }).pipe(
			map((response) => {
				this._user.next(response);
			})
		);
	}
}
